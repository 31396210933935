import React, {useEffect, useState} from 'react'
import {Button, ButtonToolbar, Form, Loader, Notification, Tag, toaster} from 'rsuite'
import {useUploadBridgeProjectsMutation} from '../../../api'
import {FileDropInput} from '../common/fileDropInput'

import Upload from '@rsuite/icons/legacy/Upload'

export function CustomViewBridgeProjoectsImporter() {
  const [file, setFile] = useState<File | null>(null)

  const [uploadCsv, {loading, error}] = useUploadBridgeProjectsMutation()

  useEffect(() => {
    if (error) {
      toaster.push(
        <Notification closable type="error" header={error.message} duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
  }, [error])

  async function onSubmit() {
    const {errors} = await uploadCsv({
      variables: {
        input: {file, filename: 'test'},
      },
    })

    if (errors) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={errors.toString()}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else {
      toaster.push(
        <Notification
          closable
          type="success"
          header="File imported"
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
  }

  async function handleDrop(files: File[]) {
    if (files.length === 0) return

    const file = files[0]
    setFile(file)
  }

  return (
    <div>
      <h1>Bridge Projects CSV Importer</h1>
      <br></br>
      <br></br>
      <h4>Charset:</h4>
      Automatically converted to UTF-8
      <br />
      <br />
      <h4>Delimiter:</h4>
      ;
      <br />
      <br />
      <h4>The following Columns are required in exactly this order:</h4>
      <code>
        Number;ResponsibleApplicant;CoApplicants;FundingInstrumentMySnf;ResearchInstitution;EffectiveStartDate;EffectiveEndDate;Title;Abstract;Keywords;InnovationFieldLevel2
        (or InnovationFieldTitle)
      </code>
      <br />
      <br />
      <h4>Date fields (EffectiveStartDate;EffectiveEndDate) must be in the following format</h4>
      <code>ISO 8601: YYYY-MM-DD. eg. 2018-09-30</code>
      <br />
      <br />
      <Form>
        <Form.Group>
          <Form.ControlLabel>Upload CSV</Form.ControlLabel>
          <div
            style={{
              maxWidth: '250px',
              height: '160px',
            }}>
            <FileDropInput
              icon={loading ? <Loader /> : <Upload />}
              text={loading ? undefined : 'drag csv here'}
              disabled={loading}
              onDrop={handleDrop}
            />
          </div>

          {file ? (
            <>
              <br></br>
              <br></br>
              <Tag
                closable
                onClose={() => {
                  setFile(null)
                }}>
                {file.name}
              </Tag>
            </>
          ) : null}
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance={file ? 'primary' : 'default'}
              disabled={!file || loading}
              onClick={() => {
                onSubmit().catch((e) => {
                  console.error(e)
                })
              }}>
              Submit
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  )
}
