import React from 'react'
import {BlockProps} from '../../atoms/blockList'
import {SliderBlockValueUnion, SliderDefaultEdge} from '../types'
import {useRecordHook} from '@dudagroup/editor'
import {ImageRecord} from '../../interfaces/interfaces'
import {DEFAULT_LANGUAGE} from '../../../../config'
import {ComponentYouTubeVideoEmbed} from '../embeds/youTube'

export function SliderPage(props: BlockProps<SliderBlockValueUnion> & {lang: string}) {
  const {value, ...rest} = props
  if (value && 'default' in value) {
    return <SliderPageDefault {...rest} value={value.default} />
  } else if (value && 'youTubeVideo' in value) {
    return <ComponentYouTubeVideoEmbed videoID={value.youTubeVideo.videoID[props.lang]} />
  }

  return null
}

export function SliderPageDefault({value, lang}: BlockProps<SliderDefaultEdge> & {lang: string}) {
  const record = useRecordHook<ImageRecord>(value.image)

  return (
    <>
      {record?.content?.media && (
        <div
          style={{
            padding: 0,
            position: 'relative',
            height: '100%',
            backgroundSize: `${
              record.content.media[DEFAULT_LANGUAGE].media.image.height > 300 ? 'contain' : 'auto'
            }`,
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${
              record.content.media[DEFAULT_LANGUAGE].media.url ??
              'https://via.placeholder.com/240x240'
            })`,
          }}>
          <p
            style={{
              textAlign: 'center',
              position: 'absolute',
              bottom: 10,
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
            {value.text[lang]}
          </p>
        </div>
      )}
    </>
  )
}
