import React from 'react'
import {BlockProps} from '../atoms/blockList'
import {AccordionBlockValue} from './types'
import {BlockObject} from '@dudagroup/editor'

export type GenericBlockProps = BlockProps<AccordionBlockValue>

export function GenericBlock(props: GenericBlockProps) {
  const {value, model, dispatch, path, configs, languageLaneL, languageLaneR, langUi} = props

  return (
    <BlockObject
      model={model}
      value={value}
      schemaPath={path}
      configs={configs}
      dispatch={dispatch}
      languageContext={{
        langLane1: languageLaneL,
        langLane2: languageLaneR,
        languagesConfig: configs.apiConfig.languages,
        langUi: langUi,
      }}></BlockObject>
  )
}
