import React, {useState} from 'react'
import {FieldProps} from '../atoms/listInput2'
import {Configs, ReferenceButton, RefSelectDrawer} from '@dudagroup/editor'
import {ExternalLink, ExternalLinkTarget, InternalLink, LinkObject} from '../blocks/types'
import {Drawer, Form, Input, InputGroup, Nav, SelectPicker, Toggle} from 'rsuite'
import {destructUnionCase, getEmptyI18nValue} from '../utility'
import update from 'immutability-helper'
import {ContentModelSchemaFieldRefTypeMap} from '@dudagroup/api'
import {useTranslation} from 'react-i18next'
import Instructions from './Instructions'

export function LinkComponent({
  value,
  configs,
  onChange,
  languageLaneL,
  languageLaneR,
  refConfig,
}: FieldProps<LinkObject> & {
  configs: Configs
  languageLaneL: string
  languageLaneR: string
  refConfig: ContentModelSchemaFieldRefTypeMap
}) {
  const [isChooseModalOpenType, setChooseModalOpenType] = useState('')
  const [unionValues, setUnionValues] = useState<any>(value)
  const {unionCase, value: link} = destructUnionCase(value)

  const {t} = useTranslation()

  let tabContent = null
  switch (unionCase) {
    case 'external': {
      const v = link as ExternalLink
      tabContent = (
        <div style={{marginTop: '20px', marginBottom: '50px', width: '50%'}}>
          <Input
            type="url"
            value={v.href[languageLaneL]}
            onChange={(v) => {
              onChange(update(value, {[unionCase]: {href: {[languageLaneL]: {$set: v}}}}))
            }}
          />
          <SelectPicker
            value={v.target || ExternalLinkTarget._blank}
            onChange={(val) => {
              if (val) onChange(update(value, {[unionCase]: {target: {$set: val}}}))
            }}
            searchable={false}
            data={[
              {
                label: '_blank',
                value: ExternalLinkTarget._blank,
              },
              {
                label: '_self',
                value: ExternalLinkTarget._self,
              },
            ]}
            style={{width: '50%', paddingTop: '10'}}
          />
        </div>
      )
      break
    }
    case 'internal': {
      const v = link as InternalLink
      tabContent = (
        <div style={{marginTop: '20px', marginBottom: '50px', width: '50%'}}>
          <ReferenceButton
            configs={configs}
            reference={v.link ? v.link : null}
            onClick={() => setChooseModalOpenType('page')}
            onClose={() => {
              onChange(update(value, {[unionCase]: {link: {$set: undefined}}}))
            }}></ReferenceButton>{' '}
          <>
            <div className="wep-label" style={{marginTop: 30, height: '24px'}}>
              <Form.ControlLabel style={{display: 'inline-block'}}>
                {t('blockList.queryParams')}
              </Form.ControlLabel>
              <Instructions
                instructions={t('blockList.queryParamsInstructions')}
                currentLanguage={languageLaneL}></Instructions>
            </div>

            <InputGroup>
              <Input
                style={{width: '100%'}}
                placeholder="key1=value1&key2=value2"
                type={'text'}
                value={v.queryParams || ''}
                onChange={(val) =>
                  onChange(update(value, {[unionCase]: {queryParams: {$set: val}}}))
                }
              />
            </InputGroup>
          </>
          <>
            <div className="wep-label" style={{marginTop: 30, height: '24px'}}>
              <Form.ControlLabel style={{display: 'inline-block'}}>
                {t('blockList.fragment')}
              </Form.ControlLabel>
              <Instructions
                instructions={t('blockList.fragmentInstructions')}
                currentLanguage={languageLaneL}></Instructions>
            </div>

            <InputGroup>
              <Input
                style={{width: '100%'}}
                placeholder="download-section"
                type={'text'}
                value={v.fragment || ''}
                onChange={(val) => onChange(update(value, {[unionCase]: {fragment: {$set: val}}}))}
              />
            </InputGroup>
          </>
        </div>
      )
      break
    }

    default:
      break
  }

  return (
    <>
      <Nav
        appearance="subtle"
        activeKey={unionCase}
        onSelect={(eventKey) => {
          let v: any = null
          setUnionValues({...unionValues, ...value})
          switch (eventKey) {
            case 'external': {
              if (unionValues.external) {
                v = {external: unionValues.external}
              } else {
                v = {
                  external: {
                    href: getEmptyI18nValue(configs.apiConfig.languages, ''),
                  },
                }
              }
              break
            }

            case 'internal': {
              if (unionValues.internal) {
                v = {internal: unionValues.internal}
              } else {
                v = {
                  internal: {
                    link: null,
                  },
                }
              }
              break
            }
            default:
              break
          }
          onChange(update(value, {$set: v}))
        }}>
        <Nav.Item eventKey="external">{t('blockList.external')}</Nav.Item>
        <Nav.Item eventKey="internal">{t('blockList.internal')}</Nav.Item>
      </Nav>
      {tabContent}
      {isChooseModalOpenType && (
        <Drawer
          placement={'bottom'}
          open={!!isChooseModalOpenType}
          size="full"
          onClose={() => setChooseModalOpenType('')}>
          <RefSelectDrawer
            configs={configs}
            refConfig={refConfig}
            onClose={() => setChooseModalOpenType('')}
            onSelectRef={(ref) => {
              setChooseModalOpenType('')
              onChange(update(value, {$set: {[unionCase]: {link: ref}} as any}))
            }}
          />
        </Drawer>
      )}
    </>
  )
}
