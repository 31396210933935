import React, {useState} from 'react'
import {Button, ButtonToolbar, Form} from 'rsuite'
import fetch from 'node-fetch'

export function CustomViewPurgecache() {
  const [status, setStatus] = useState('')

  async function onSubmit() {
    await fetch('/purgecache')
      .then((response) => {
        if (response.status === 200) {
          setStatus('Cache cleared')
          return
        }
        throw new Error('Request failed.')
      })
      .catch((error) => {
        console.log(error)
        setStatus('error purging cache')
      })
  }

  return (
    <div>
      <h1>Clear cache</h1>
      <br></br>
      <br></br>
      <Form>
        <Form.Group>
          <ButtonToolbar>
            <Button
              onClick={() => {
                onSubmit().catch((e) => {
                  console.error(e)
                })
              }}>
              Clear cache
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
      <br></br>
      <p>{status}</p>
    </div>
  )
}
