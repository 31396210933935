import React from 'react'
import {SortableHandle, SortableContainer, SortableElement} from 'react-sortable-hoc'
import {arrayMoveImmutable} from 'array-move'
import {isFunctionalUpdate} from '@dudagroup/editor'
import {IconButton, Panel} from 'rsuite'
import {useTranslation} from 'react-i18next'
import {ListWrapper} from '@dudagroup/editor/lib/client/interfaces/extensionConfig'
import {generateID} from '../utility'

import Arrows from '@rsuite/icons/legacy/Arrows'
import MinusSquareO from '@rsuite/icons/legacy/MinusSquareO'
import PlusCircle from '@rsuite/icons/legacy/PlusCircle'

export interface FieldProps<V = any> {
  readonly value: V
  readonly onChange: React.Dispatch<React.SetStateAction<V>>
}

export interface ListFieldProps<T = any> extends FieldProps<T[]> {
  readonly label?: string
  readonly defaultValue: T | (() => T)
  readonly disabled?: boolean
  readonly children: (props: FieldProps<T>) => JSX.Element
}

export interface ListItemProps<T = any> {
  readonly value: T[]
  readonly itemIndex: number
  readonly itemDisabled?: boolean
  readonly onChange: (index: number, value: React.SetStateAction<T>) => void
  readonly onRemove: (index: number) => void
  readonly children: (props: FieldProps<T>) => JSX.Element
}

const DragHandle = SortableHandle(({disabled}: {disabled?: boolean}) => (
  <IconButton icon={<Arrows />} disabled={disabled} />
))

const ListItem = SortableElement((props: ListItemProps) => {
  const {value, itemIndex, itemDisabled, onChange, onRemove, children} = props

  function handleValueChange(fieldValue: React.SetStateAction<any>) {
    onChange(itemIndex, (value: any) => ({
      ...value,
      ...(isFunctionalUpdate(fieldValue) ? fieldValue(value) : fieldValue),
    }))
  }

  function handleRemove() {
    onRemove(itemIndex)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '10px',
      }}>
      <div style={{marginRight: '10px'}}>
        <DragHandle disabled={itemDisabled} />
      </div>
      <Panel bodyFill style={{width: '100%'}}>
        <div style={{minHeight: '100%'}}>
          {children({value: value, onChange: handleValueChange})}
        </div>
      </Panel>
      <div style={{marginLeft: '10px'}}>
        <IconButton icon={<MinusSquareO />} onClick={handleRemove} disabled={itemDisabled} />
      </div>
    </div>
  )
})

const SortableList = SortableContainer((props: ListFieldProps) => {
  const {value, defaultValue, disabled, children, onChange} = props
  const {t} = useTranslation()

  function handleItemChange(itemIndex: number, itemValue: React.SetStateAction<any>) {
    onChange((value) => {
      const newVal = isFunctionalUpdate(itemValue) ? itemValue(value[itemIndex].content) : itemValue
      return Object.assign([], value, {
        [itemIndex]: {...value[itemIndex], content: newVal},
      })
    })
  }

  function handleAdd() {
    const listWrapper: ListWrapper = {
      id: generateID(),
      expanded: false,
      content: defaultValue,
    }
    onChange((value) => [...value, listWrapper])
  }

  function handleRemove(itemIndex: number) {
    onChange((value) => value.filter((_, index) => index !== itemIndex))
  }

  return (
    <div>
      {value.map((value: ListWrapper<unknown>, index: number) => (
        <ListItem
          key={value.id}
          itemIndex={index}
          index={index}
          value={value.content as any}
          itemDisabled={disabled}
          onChange={handleItemChange}
          onRemove={handleRemove}>
          {children}
        </ListItem>
      ))}
      <IconButton
        placement="left"
        appearance="ghost"
        icon={<PlusCircle />}
        onClick={handleAdd}
        disabled={disabled}>
        {t('global.buttons.add')}
      </IconButton>
    </div>
  )
})

export function ListInput<T>({
  value,
  label,
  defaultValue,
  disabled,
  children,
  onChange,
}: ListFieldProps<T>) {
  const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) => {
    onChange(arrayMoveImmutable(value, oldIndex, newIndex))
  }

  return (
    <div>
      {label && <label>{label}</label>}
      <SortableList
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        children={children} // eslint-disable-line react/no-children-prop
        onChange={onChange}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </div>
  )
}
