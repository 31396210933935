import React, {useEffect, useReducer, useState} from 'react'
import {BlockList, BlockProps} from '../atoms/blockList'
import {AccordionBlockValue} from './types'
import {I18nWrapper} from '../atoms/i18nWrapper'
import {Form, Button, Drawer, Grid, Input, Panel} from 'rsuite'
import LanguageControl from '@dudagroup/editor/lib/client/atoms/contentEdit/LanguageControl'
import {
  ContentEditActionEnum,
  contentReducer,
} from '@dudagroup/editor/lib/client/control/contentReducer'
import {useTranslation} from 'react-i18next'
import {ContentModelConfigMergedBase} from '@dudagroup/editor'

export type AccordionEditProps = BlockProps<AccordionBlockValue>

export function AccordionEdit({
  value,
  onChange,
  disabled,
  configs,
  currentPanelId,
  onClose,
  langUi,
}: AccordionEditProps & {currentPanelId: string | null; onClose: (val: any) => void}) {
  const {t} = useTranslation()
  const {accordion} = value
  const i = accordion.findIndex((p) => {
    return p.content.id === currentPanelId
  })
  const currentPanel = accordion[i]?.content
  const [langLane1, setLangLane1] = useState(configs.apiConfig.languages.languages?.[0]?.tag)
  const [langLane2, setLangLane2] = useState(configs.apiConfig.languages.languages?.[1]?.tag)

  const contentConfig = configs.extensionMerged.find((config) => {
    return config.identifier === 'page'
  })

  const [{record}, dispatcher] = useReducer(contentReducer, {
    record: {blocks: currentPanel?.content},
    hasChanged: false,
  })

  useEffect(() => {
    dispatcher({
      type: ContentEditActionEnum.setInitialState,
      value: {blocks: currentPanel?.content},
    })
  }, [currentPanel])

  if (!(currentPanelId && record?.blocks && contentConfig)) {
    return null
  }

  const header = (
    <LanguageControl
      languagesConfig={configs.apiConfig.languages}
      langLaneL={langLane1}
      langLaneR={langLane2}
      setLangLaneL={setLangLane1}
      setLangLaneR={setLangLane2}
    />
  )

  return (
    <Drawer
      open={!!currentPanelId}
      placement={'bottom'}
      size="full"
      backdrop="static"
      onClose={() => {
        onClose(record.blocks)
      }}>
      <Drawer.Header>
        <Drawer.Title>{t('blockList.accordionGroup')}</Drawer.Title>

        <Drawer.Actions>
          <Button
            appearance={'primary'}
            color={'green'}
            onClick={() => {
              onClose(record.blocks)
            }}>
            {t('blockList.apply')}
          </Button>
          {/* <Button
          appearance={'subtle'}
          onClick={() => {
            onClose(record.blocks)
          }}>
          Close
        </Button> */}
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body>
        <Grid>
          <div className="wep-editor-langcontrol">{header}</div>
          <Panel
            bordered
            style={{
              overflow: 'visible',
            }}>
            <I18nWrapper
              lane1={
                <>
                  <div className="wep-label">
                    <Form.ControlLabel style={{display: 'inline-block'}}>Name</Form.ControlLabel>
                  </div>
                  <Input
                    value={currentPanel.title[langLane1]}
                    onChange={(val) => {
                      onChange(record.blocks, ['accordion', i, 'content', 'content'])
                      onChange(val, ['accordion', i, 'content', 'title', langLane1])
                    }}
                  />
                </>
              }
              lane2={
                <>
                  <div className="wep-label">
                    <Form.ControlLabel style={{display: 'inline-block'}}>Name</Form.ControlLabel>
                  </div>
                  <Input
                    value={currentPanel.title[langLane2]}
                    onChange={(val) => {
                      onChange(record.blocks, ['accordion', i, 'content', 'content'])
                      onChange(val, ['accordion', i, 'content', 'title', langLane2])
                    }}
                  />
                </>
              }
            />
          </Panel>
          <BlockList
            value={record}
            disabled={disabled}
            dispatch={dispatcher}
            configs={configs}
            blockCases={
              (contentConfig as ContentModelConfigMergedBase).schema.content.blocks.contentType
                .cases.accordion.fields.accordion.contentType.fields.content.contentType.cases
            }
            languageLaneL={langLane1}
            languageLaneR={langLane2}
            langUi={langUi}></BlockList>
        </Grid>
      </Drawer.Body>
    </Drawer>
  )
}
