import React, {useEffect, useState} from 'react'
import {Button, Form, Loader, Table, Notification, toaster} from 'rsuite'
import {useFetchMultiUploadLazyQuery, useMultiUploadFileMutation} from '../../../api'
import {FileDropInput} from '../common/fileDropInput'
import {useTranslation} from 'react-i18next'
import {marked} from 'marked'

import ExclamationTriangle from '@rsuite/icons/legacy/ExclamationTriangle'
import Upload from '@rsuite/icons/legacy/Upload'
import Check from '@rsuite/icons/legacy/Check'

const {Column, HeaderCell, Cell} = Table

interface ListItems {
  mediaId: string
  fileName: string
  title: string
  language: string
  altText: string
  createdAt: string
  modifiedAt: string
  mediaIsNew: boolean
  mediaIsNewForLanguage: boolean
  file: File | undefined
  uploaded?: boolean
}

interface CellProps {
  rowData?: any
  dataKey: string | number
  onChange?: (rowData: string, dataKey: string | number, event: string) => void
  onClick?: (rowData: string) => void
}

// TODO: npm check if this component still works as expected
export default function CustomViewFilesUpload() {
  const [list, setList] = useState<ListItems[]>([])
  const [fileList, setFileList] = useState<File[]>([])
  const [uploading, setUploading] = useState(false)
  const [checkFileNames, {data, error, loading}] = useFetchMultiUploadLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [uploadFile] = useMultiUploadFileMutation()
  const styles = {
    height: '200px',
  }
  const {t} = useTranslation()

  useEffect(() => {
    // check for checkFileName Errors
    if (error) {
      toaster.push(
        <Notification closable type="error" header="File check Error" duration={5000}>
          {error.toString()}
        </Notification>,
        {placement: 'topEnd'}
      )
      // if no errors, add files to list
    } else if (data) {
      setList(
        data?.extensions.mediaMultiUpload.map((response) => {
          const file: File | undefined = fileList.find((file) => file.name === response.fileName)
          return {
            ...response,
            id: __filename,
            file: file,
          }
        })
      )
    }
  }, [data, error])

  /**************************/
  /* Data fetching Methods  */
  /**************************/

  const handleDrop = (files: File[]) => {
    setFileList(files)
    const fileNames = files.map((file) => {
      return file.name
    })
    checkFileNames({
      variables: {
        files: fileNames,
      },
    })
  }

  const files = [...list] // Copy the state to update it later on with upload success info

  const handleUpload = async () => {
    setUploading(true)
    for (let index = 0; index < list.length; index++) {
      const upload = {
        variables: {
          file: list[index].file,
          filename: list[index].fileName,
          title: list[index]?.title || list[index].fileName,
          altText: list[index]?.altText || list[index].fileName,
        },
      }
      const file = {...files[index]}
      try {
        await uploadFile(upload)

        file.uploaded = true
      } catch (error) {
        toaster.push(
          <Notification closable type="error" header="Uplad Error" duration={5000}>
            {(error as Error).toString()}
          </Notification>,
          {placement: 'topEnd'}
        )
        file.uploaded = false
      }
      files[index] = file
    }
    setList(files)
    setUploading(false)
  }

  /*****************************/
  /* Rendering stuff in RSuite  */
  /*****************************/

  const handleChange = (id: any, key: string | number, value: any) => {
    if (list !== undefined) {
      const nextData: any = Object.assign([], list)
      const activeItem = nextData.find((item: any) => item.fileName === id)
      activeItem[key] = value
    }
  }
  const handleEditState = (id: any) => {
    const nextData: any = Object.assign([], list)
    const activeItem = nextData.find((item: any) => item.fileName === id)
    activeItem.status = activeItem.status ? null : 'EDIT'
    setList(nextData)
  }

  const handleRemove = (id: number) => {
    const nextData = Object.assign([], list)
    const activeItem = nextData.find((item: any) => item.fileName === id)
    const filteredItems = nextData.filter((item) => item !== activeItem)

    setList(filteredItems)
  }

  // handy function that returns a valid hex-color code when passing it any string.
  const colorizeGroups = (mediaId: string) => {
    let hash = 0
    for (let i = 0; i < mediaId.length; i++) {
      hash = mediaId.charCodeAt(i) + ((hash << 4) - hash)
    }
    let colour = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      colour += ('00' + value.toString(16)).substr(-2)
    }
    return colour
  }

  const NameCell = ({rowData, dataKey, onChange, onClick, ...props}: CellProps) => {
    return (
      <Cell {...props}>
        {!rowData.mediaIsNew ? (
          <span className="table-content-edit-span">
            <a
              href={`/content/media/edit/${rowData.mediaId}`}
              target="_blank"
              rel="noreferrer"
              title={rowData[dataKey] || ''}
              style={{textDecoration: 'underline'}}>
              {rowData[dataKey] || t('fileUploader.empty')}
            </a>
          </span>
        ) : (
          <span className="table-content-edit-span" title={rowData[dataKey] || ''}>
            {rowData[dataKey] || t('fileUploader.empty')}
          </span>
        )}
      </Cell>
    )
  }

  const EditCell = ({rowData, dataKey, onChange, onClick, ...props}: CellProps) => {
    const editing = rowData.status === 'EDIT'
    const emptyStyles = {
      color: 'LightGray',
    }
    return (
      <Cell {...props} className={editing ? 'table-content-editing' : ''}>
        {editing ? (
          <input
            className="rs-input"
            defaultValue={rowData[dataKey]}
            onChange={(event) => {
              onChange && onChange(rowData.fileName, dataKey, event.target.value)
            }}
          />
        ) : (
          <span
            className="table-content-edit-span"
            style={rowData[dataKey] ? {} : emptyStyles}
            onClick={() => {
              onClick && onClick(rowData.fileName)
            }}>
            {rowData[dataKey] || t('fileUploader.empty')}
          </span>
        )}
      </Cell>
    )
  }

  const ActionCell = ({rowData, dataKey, onChange, onClick, ...props}: CellProps) => {
    return (
      <Cell {...props} style={{padding: '0'}}>
        <div>
          <Button
            appearance="link"
            style={{padding: '0 6px'}}
            onClick={() => {
              onClick && onClick(rowData.fileName)
            }}>
            {rowData.status === 'EDIT' ? t('fileUploader.save') : t('fileUploader.edit')}
          </Button>
          {rowData.status !== 'EDIT' && (
            <>
              |
              <Button
                appearance="link"
                onClick={() => {
                  handleRemove(rowData.fileName)
                }}
                style={{color: 'tomato', padding: '0 6px'}}>
                {t('fileUploader.remove')}
              </Button>
            </>
          )}
        </div>
      </Cell>
    )
  }

  const WarningCell = ({rowData, dataKey, onChange, onClick, ...props}: CellProps) => {
    const filesUploaded = Object.prototype.hasOwnProperty.call(rowData, 'uploaded')
    const fileSize = `${(rowData?.file.size / 1000000).toLocaleString('de', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}mb`

    return (
      <Cell {...props} style={{padding: '6px 0'}}>
        {isFileTooBig(rowData.file.size) && (
          <div style={{display: 'flex', color: 'tomato', marginBottom: '0.2rem'}}>
            <ExclamationTriangle style={{fontSize: '2em', marginRight: '1rem'}} />
            <p>
              {t('fileUploader.fileTooBig')} <br />{' '}
              <small>
                {t('fileUploader.fileSize')}: {fileSize}
              </small>
            </p>
          </div>
        )}
        {!filesUploaded && !rowData.mediaIsNewForLanguage && (
          <div style={{display: 'flex', color: 'tomato'}}>
            <ExclamationTriangle style={{fontSize: '2em', marginRight: '1rem'}} />
            <p>{t('fileUploader.overwriting')}</p>
          </div>
        )}
        {!filesUploaded && !rowData.mediaIsNew && rowData.mediaIsNewForLanguage && (
          <div style={{display: 'flex', color: 'LightSalmon'}}>
            <ExclamationTriangle style={{fontSize: '2em', marginRight: '1rem'}} />
            <p>{t('fileUploader.adding')}</p>
          </div>
        )}
        {filesUploaded &&
          (rowData.uploaded ? (
            <div style={{display: 'flex', color: 'SpringGreen'}}>
              <Check style={{fontSize: '2em', marginRight: '1rem'}} />
              <p>{t('fileUploader.success')}</p>
            </div>
          ) : (
            <div style={{display: 'flex', color: 'tomato'}}>
              <ExclamationTriangle style={{fontSize: '2em', marginRight: '1rem'}} />
              <p>{t('fileUploader.noSuccess')}</p>
            </div>
          ))}
      </Cell>
    )
  }

  const MediaCell = ({rowData, dataKey, onChange, onClick, ...props}: CellProps) => {
    return (
      <Cell {...props} style={{padding: '0', color: colorizeGroups(rowData.mediaId)}}>
        <p>{rowData.mediaId}</p>
      </Cell>
    )
  }

  const isFileTooBig = (size: number): boolean => {
    return size > 1000000
  }

  function toComponent(html: string) {
    return <div dangerouslySetInnerHTML={{__html: html}} />
  }

  return (
    <>
      <h3>{t('fileUploader.mediaUpload')}</h3>
      {toComponent(marked(t('fileUploader.mediaUploadDocumentation')))}
      <Form>
        <Form.Group>
          <div style={styles}>
            <FileDropInput
              icon={loading ? <Loader /> : <Upload />}
              text={loading ? undefined : t('fileUploader.dragchoose')}
              disabled={loading}
              onDrop={handleDrop}
              multiple
            />
          </div>
        </Form.Group>
      </Form>
      {list.length > 0 && (
        <>
          <br></br>
          <br></br>
          {uploading ? (
            <Loader content={t('fileUploader.uploadingFiles')} vertical />
          ) : (
            <Table
              data={list}
              height={(list.length + 1) * 100}
              rowHeight={100}
              sortColumn="mediaId"
              style={{overflowY: 'scroll'}}>
              <Column width={200} verticalAlign="middle">
                <HeaderCell>File Name</HeaderCell>
                <NameCell dataKey="fileName" />
              </Column>
              <Column width={200} verticalAlign="middle">
                <HeaderCell>{t('fileUploader.title')}</HeaderCell>
                <EditCell dataKey="title" onChange={handleChange} onClick={handleEditState} />
              </Column>
              <Column width={200} verticalAlign="middle">
                <HeaderCell>Alt Text</HeaderCell>
                <EditCell dataKey="altText" onChange={handleChange} onClick={handleEditState} />
              </Column>
              <Column width={80} verticalAlign="middle">
                <HeaderCell>{t('fileUploader.language')}</HeaderCell>
                <EditCell dataKey="language" />
              </Column>
              <Column width={200} verticalAlign="middle">
                <HeaderCell>Media Id</HeaderCell>
                <MediaCell dataKey="mediaId" />
              </Column>
              <Column width={200} verticalAlign="middle">
                <HeaderCell>Action</HeaderCell>
                <ActionCell dataKey="id" onClick={handleEditState} />
              </Column>
              <Column flexGrow={1} verticalAlign="middle">
                <HeaderCell>{t('fileUploader.warnings')}</HeaderCell>
                <WarningCell dataKey="id" />
              </Column>
            </Table>
          )}
        </>
      )}
      <br />
      <br />
      <div>
        <Button
          disabled={list.length == 0}
          appearance="primary"
          size="lg"
          onClick={() => {
            handleUpload()
          }}
          style={{
            marginBottom: '1rem',
          }}>
          {t('fileUploader.upload')}
        </Button>
      </div>
    </>
  )
}
