import React, {
  useEffect,
  useRef,
  TextareaHTMLAttributes,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
  ChangeEvent,
} from 'react'

import {TypographyVariant, stylesForTypographyVariant, TypographyTextAlign} from './typography'

export interface TypographicTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  readonly variant?: TypographyVariant
  readonly align?: TypographyTextAlign
}

// eslint-disable-next-line react/display-name
export const TypographicTextArea = forwardRef<HTMLTextAreaElement, TypographicTextAreaProps>(
  ({variant = 'body1', align = 'left', onChange, ...props}, forwardRef) => {
    const ref = useRef<HTMLTextAreaElement>(null)

    useImperativeHandle(forwardRef, () => ref.current!, [ref.current])

    useLayoutEffect(() => {
      handleResize()
    }, [])

    useEffect(() => {
      if (typeof ResizeObserver !== 'undefined') {
        const observer = new ResizeObserver((entries) => {
          requestAnimationFrame(() => {
            handleResize()
          })
        })
        const currentRef = ref.current
        if (!currentRef) return
        observer.observe(ref.current)
        return () => observer.unobserve(currentRef)
      } else {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
      }
    }, [ref])

    function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
      handleResize()
      onChange?.(e)
    }

    function handleResize() {
      if (ref.current) {
        ref.current.style.overflow = 'hidden'
        ref.current.style.height = 'auto'
        ref.current.style.height = `${ref.current.scrollHeight}px`
      }
    }

    return (
      <textarea
        ref={ref}
        {...props}
        style={{
          display: 'block',

          width: '100%',
          resize: 'none',

          borderStyle: 'none',
          borderWidth: 0,
          borderColor: 'transparent',

          fontFamily: 'inherit',
          lineHeight: 1.375,
          overflow: 'hidden',
          height: 'auto',

          color: 'black',
          textAlign: align,
          ...stylesForTypographyVariant(variant),

          outline: 'none !important',
        }}
        onChange={handleChange}
        rows={1}
      />
    )
  }
)
