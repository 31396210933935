import React, {useState} from 'react'
import {Button, ButtonToolbar, Form, Notification, toaster} from 'rsuite'
import {useReindexMutation} from '../../../api'

export function CustomViewReindex() {
  const [done, setDone] = useState(false)
  const [reindex, {error, loading}] = useReindexMutation()

  async function onSubmit() {
    await reindex({})

    if (error) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={error.toString()}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else {
      setDone(true)
      toaster.push(
        <Notification
          closable
          type="success"
          header="Indexing started"
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
  }

  return (
    <div>
      <h1>Reindex Search</h1>
      <br></br>
      <br></br>
      <Form>
        <Form.Group>
          <ButtonToolbar>
            <Button
              disabled={done || loading}
              onClick={() => {
                onSubmit().catch((e) => {
                  console.error(e)
                })
              }}>
              Reindex
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  )
}
