import {
  BlockObject,
  Configs,
  ContentModelConfigMergedBase,
  getContentViewFunction,
  getPreviewLinkFunction,
} from '@dudagroup/editor'
import {ContentEditAction} from '@dudagroup/editor/lib/client/control/contentReducer'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Grid, Panel, Message} from 'rsuite'
import {BlockList} from './atoms/blockList'

export const getGenericViewWithCustomBlockList: getContentViewFunction = (
  content: any,
  handleChange: any,
  disabled: boolean,
  dispatch: React.Dispatch<ContentEditAction>,
  configs: Configs,
  contentModelConfigMerged: ContentModelConfigMergedBase,
  langLaneL,
  langLaneR,
  langUi
) => {
  return (
    <GenericViewWithBlockList
      {...{
        content,
        handleChange,
        disabled,
        dispatch,
        configs,
        contentModelConfigMerged,
        langLaneL,
        langLaneR,
        langUi,
      }}></GenericViewWithBlockList>
  )
}

interface GenericViewWithBlockList {
  content: any
  handleChange: any
  disabled: boolean
  dispatch: React.Dispatch<ContentEditAction>
  configs: Configs
  contentModelConfigMerged: ContentModelConfigMergedBase
  langLaneL: string
  langLaneR: string
  langUi: string
}

function GenericViewWithBlockList(props: GenericViewWithBlockList) {
  const {
    content,
    handleChange,
    disabled,
    dispatch,
    configs,
    contentModelConfigMerged,
    langLaneL,
    langLaneR,
    langUi,
  } = props
  const {blocks, ...rest} = contentModelConfigMerged.schema.content
  const {t} = useTranslation()
  return (
    <Grid>
      <Panel
        collapsible
        defaultExpanded
        header={<strong>{t('content.panels.contentSectionTitle')}</strong>}
        bordered
        style={{
          overflow: 'visible',
        }}>
        <BlockObject
          model={{
            type: 'object' as any,
            fields: rest,
          }}
          value={content}
          schemaPath={[]}
          configs={configs}
          dispatch={dispatch}
          languageContext={{
            langLane1: langLaneL,
            langLane2: langLaneR,
            languagesConfig: configs.apiConfig.languages,
            langUi: langUi,
          }}></BlockObject>
      </Panel>
      <Message style={{marginTop: 20}}>{<strong>{t('blockList.contentBlocks')}</strong>}</Message>
      <BlockList
        value={content}
        disabled={disabled}
        dispatch={dispatch}
        configs={configs}
        blockCases={contentModelConfigMerged.schema.content.blocks.contentType.cases}
        languageLaneL={langLaneL}
        languageLaneR={langLaneR}
        langUi={langUi}></BlockList>
    </Grid>
  )
}

export const getPreviewLink: getPreviewLinkFunction = (token, language, recordData) => {
  const settings = document.getElementById('settings')
  if (settings?.textContent) {
    const {websiteUrl} = JSON.parse(settings.textContent)
    return `${websiteUrl}/api/preview/?lang=${language}&contentType=${recordData.contentType}&id=${recordData.id}&slug=${recordData.slugI18n[language]}&revision=${recordData.revision}&token=${token}`
  }

  throw new Error('Was not able to create preview link')
}
