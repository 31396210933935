import React, {useState} from 'react'
import {IconButton, Panel} from 'rsuite'
import {PlaceholderInput} from '../../atoms/placeholderInput'
import {BlockProps} from '../../atoms/blockList'
import {SliderBlockValue} from '../types'
import {SliderPage} from './sliderBlockPage'
import {SliderBlockEdit} from './sliderBlockEdit'
import {I18nWrapper} from '../../atoms/i18nWrapper'

import List from '@rsuite/icons/legacy/List'
import ArrowCircleLeft from '@rsuite/icons/legacy/ArrowCircleLeft'
import ArrowCircleRight from '@rsuite/icons/legacy/ArrowCircleRight'

export function SliderBlockI18n(props: BlockProps<SliderBlockValue>) {
  const {languageLaneL, languageLaneR} = props

  return (
    <>
      <Panel
        bodyFill={true}
        bordered={true}
        style={{
          padding: 0,
          margin: 0,
          overflow: 'hidden',
          border: 'none',
        }}>
        <I18nWrapper
          lane1={<SliderBlock {...props} lang={languageLaneL}></SliderBlock>}
          lane2={<SliderBlock {...props} lang={languageLaneR} disabled={true}></SliderBlock>}
        />
      </Panel>
    </>
  )
}

export function SliderBlock(
  props: BlockProps<SliderBlockValue> & {lang: string; disabled?: boolean}
) {
  const {disabled} = props
  const {value, ...rest} = props
  const [isGalleryListEditModalOpen, setGalleryListEditModalOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const item = value.slider[index]?.content
  const hasPrevious = index > 0
  const hasNext = index < value.slider.length - 1
  const isNewIndex = false

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
        }}>
        <div
          style={{
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
          }}>
          <IconButton
            icon={<List />}
            onClick={() => setGalleryListEditModalOpen(true)}
            disabled={disabled}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexBasis: 0,
            justifyContent: 'center',
            flexGrow: 1,
            flexShrink: 1,
          }}>
          <p style={{color: 'gray'}} color="gray">
            {index + 1} / {Math.max(index + 1, value.slider.length)} {isNewIndex ? '(New)' : ''}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexBasis: 0,
            justifyContent: 'flex-end',
            flexGrow: 1,
            flexShrink: 1,
          }}>
          <IconButton
            icon={<ArrowCircleLeft />}
            onClick={() => setIndex((index) => index - 1)}
            disabled={!hasPrevious}
            style={{
              marginRight: 5,
            }}
          />
          <IconButton
            icon={<ArrowCircleRight />}
            onClick={() => setIndex((index) => index + 1)}
            disabled={!hasNext}
            style={{
              marginRight: 10,
            }}
          />
        </div>
      </div>
      <Panel
        bordered={true}
        bodyFill={true}
        style={{
          height: 240,
          overflow: 'hidden',
          marginBottom: 10,
        }}>
        <PlaceholderInput
          onAddClick={() => {
            //
          }}>
          <SliderPage {...rest} value={item} lang={props.lang} />
        </PlaceholderInput>
      </Panel>

      {isGalleryListEditModalOpen && (
        <SliderBlockEdit
          {...rest}
          value={value}
          onClose={() => {
            setGalleryListEditModalOpen(false)
          }}></SliderBlockEdit>
      )}
    </>
  )
}
