import React, {useState} from 'react'
import {BlockProps} from '../../atoms/blockList'
import {SliderBlockValue, SliderDefaultEdge, YouTubeVideoEmbed} from '../types'
import {Drawer, Grid, Panel} from 'rsuite'
import LanguageControl from '@dudagroup/editor/lib/client/atoms/contentEdit/LanguageControl'
import {BlockObject} from '@dudagroup/editor'

export type SliderBlockEditProps = BlockProps<SliderDefaultEdge | YouTubeVideoEmbed>

export function SliderBlockEdit(props: BlockProps<SliderBlockValue> & {onClose: () => void}) {
  const {configs, onClose, model, value, path, dispatch} = props
  const [langLane1, setLangLane1] = useState(configs.apiConfig.languages.languages?.[0]?.tag)
  const [langLane2, setLangLane2] = useState(configs.apiConfig.languages.languages?.[1]?.tag)

  const header = (
    <LanguageControl
      languagesConfig={configs.apiConfig.languages}
      langLaneL={langLane1}
      langLaneR={langLane2}
      setLangLaneL={setLangLane1}
      setLangLaneR={setLangLane2}
    />
  )

  return (
    <Drawer
      open={true}
      placement={'bottom'}
      size="full"
      backdrop="static"
      onClose={() => {
        onClose()
      }}>
      <Drawer.Header>
        <Drawer.Title>Panel</Drawer.Title>
      </Drawer.Header>

      <Drawer.Body>
        <Grid>
          <Panel
            bordered
            style={{
              overflow: 'visible',
            }}>
            <div className="wep-editor-langcontrol">{header}</div>
            <BlockObject
              model={model}
              value={value}
              schemaPath={path}
              configs={configs}
              dispatch={dispatch}
              languageContext={{
                langLane1: langLane1,
                langLane2: langLane2,
                languagesConfig: configs.apiConfig.languages,
                langUi: props.langUi,
              }}></BlockObject>
          </Panel>
        </Grid>
      </Drawer.Body>
    </Drawer>
  )
}
