import React from 'react'

import {Divider, Dropdown, IconButton} from 'rsuite'
import {IconProps} from '@rsuite/icons/lib/Icon'
import {useTranslation} from 'react-i18next'

import Plus from '@rsuite/icons/legacy/Plus'
import Paste from '@rsuite/icons/legacy/Paste'

export interface MenuProps {
  readonly items: Array<MenuItem>

  onItemClick(item: MenuItem): void
}

export interface MenuItem {
  readonly id: string
  readonly icon: React.ReactElement<IconProps>
  readonly label: string
}

export interface AddBlockInputProps {
  menuItems: Array<MenuItem>
  subtle?: boolean
  disabled?: boolean

  onMenuItemClick: (item: MenuItem) => void
  onPaste: () => void
}

export function AddBlockInput({
  menuItems,
  subtle,
  disabled,
  onMenuItemClick,
  onPaste,
}: AddBlockInputProps) {
  const {t} = useTranslation()
  return (
    <div
      style={{
        position: 'relative',
      }}>
      <Divider>
        <Dropdown
          disabled={disabled}
          renderToggle={(props, ref) => (
            <IconButton {...props} ref={ref} appearance="ghost" size="xs" icon={<Plus />} circle />
          )}>
          <Dropdown.Item onSelect={onPaste}>
            <Paste /> {t('blockList.paste')}
          </Dropdown.Item>
          {menuItems.map((item, index) => (
            <Dropdown.Item
              key={index}
              onSelect={(event) => {
                onMenuItemClick(item)
              }}>
              {/* TODO: check if this works? */}
              {item.icon} {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </Divider>
    </div>
  )
}
