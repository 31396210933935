import React, {useState, useEffect} from 'react'
import {PlaceholderInput} from '../atoms/placeholderInput'
import {Drawer, Panel, Button} from 'rsuite'
import {BlockProps} from '../atoms/blockList'
import {EmbedEditPanel} from '../panel/embedEditPanel'
import {
  EmbedBlockValue,
  EmbedType,
  FacebookPostEmbed,
  FacebookVideoEmbed,
  InstagramPostEmbed,
  OtherEmbed,
  SoundCloudTrackEmbed,
  TwitterTweetEmbed,
} from './types'
import {ComponentYouTubeVideoEmbed} from './embeds/youTube'
import {ComponentVimeoVideoEmbed} from './embeds/vimeo'
import {IframeEmbed} from './embeds/iframe'
import {useTranslation} from 'react-i18next'
import {destructUnionCase} from '../utility'
import {ComponentSoundCloudTrackEmbed} from './embeds/soundCloud'
import {ComponentFacebookPostEmbed, ComponentFacebookVideoEmbed} from './embeds/facebook'
import {ComponentInstagramPostEmbed} from './embeds/instagram'
import {ComponentTwitterTweetEmbed} from './embeds/twitter'
import {I18nWrapper} from '../atoms/i18nWrapper'

export interface VimeoVideoEmbedSingleLang {
  videoID: string
}

export interface YouTubeVideoEmbedSingleLang {
  videoID: string
}

export interface OtherEmbedSingleLang {
  url?: string
  title?: string
  width?: number
  height?: number
  styleCustom?: string
}

export type EmbedBlockValueSingleLang = {
  embedCode?: string
  type: EmbedBlockTypeUnionSingleLang
}

export type EmbedBlockTypeUnionSingleLang = {
  [EmbedType.Other]?: OtherEmbedSingleLang
  [EmbedType.VimeoVideo]?: VimeoVideoEmbedSingleLang
  [EmbedType.YouTubeVideo]?: YouTubeVideoEmbedSingleLang
  [EmbedType.TwitterTweet]?: TwitterTweetEmbed
  [EmbedType.FacebookPost]?: FacebookPostEmbed
  [EmbedType.FacebookVideo]?: FacebookVideoEmbed
  [EmbedType.InstagramPost]?: InstagramPostEmbed
  [EmbedType.SoundCloudTrack]?: SoundCloudTrackEmbed
}

// TODO: Handle disabled prop
export function EmbedBlock({
  value: valI18n,
  onChange,
  autofocus,
  languageLaneL,
  languageLaneR,
}: BlockProps<EmbedBlockValue>) {
  const [isEmbedDialogOpen, setEmbedDialogOpen] = useState(false)
  const {t} = useTranslation()
  const valueLaneL = i18nToVal(languageLaneL, valI18n)
  const {unionCase: unionCaseLaneL, value: valLaneL} = destructUnionCase(valueLaneL.type)
  const isEmptyLaneL = unionCaseLaneL === EmbedType.Other && !(valLaneL as OtherEmbed).url

  const valueLaneR = i18nToVal(languageLaneR, valI18n)
  const {unionCase: unionCaseLaneR, value: valLaneR} = destructUnionCase(valueLaneL.type)
  const isEmptyLaneR = unionCaseLaneL === EmbedType.Other && !(valLaneL as OtherEmbed).url

  useEffect(() => {
    if (autofocus && isEmptyLaneL) {
      setEmbedDialogOpen(true)
    }
  }, [])

  return (
    <>
      <Panel
        bodyFill={true}
        bordered={true}
        style={{
          minHeight: 100,
          padding: 0,
          overflow: 'hidden',
          border: 'none',
        }}>
        <Button
          size={'md'}
          style={{marginBottom: 40}}
          appearance={'ghost'}
          onClick={() => setEmbedDialogOpen(true)}>
          {t('blocks.embeds.overview.editEmbed')}
        </Button>
        <br />
        <I18nWrapper
          lane1={
            <PlaceholderInput onAddClick={() => setEmbedDialogOpen(true)}>
              {!isEmptyLaneL && (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                  }}>
                  <EmbedPreview value={valueLaneL} />
                </div>
              )}
            </PlaceholderInput>
          }
          lane2={valLaneR && <EmbedPreview value={valueLaneR} />}></I18nWrapper>
      </Panel>
      <Drawer
        placement="bottom"
        size="full"
        open={isEmbedDialogOpen}
        onClose={() => setEmbedDialogOpen(false)}>
        <EmbedEditPanel
          value={valueLaneL}
          embedCode={valueLaneL.embedCode || ''}
          onClose={() => setEmbedDialogOpen(false)}
          onConfirm={(value) => {
            setEmbedDialogOpen(false)
            const vt18n = valToI18n(languageLaneL, valI18n, value)
            console.log({vt18n})

            onChange({...vt18n}, [])
          }}
        />
      </Drawer>
    </>
  )
}

export interface EmbedPreviewProps {
  readonly value: EmbedBlockValueSingleLang
}

export function EmbedPreview({value}: EmbedPreviewProps) {
  const {unionCase, value: val} = destructUnionCase(value.type)

  switch (unionCase) {
    case EmbedType.FacebookPost:
      return <ComponentFacebookPostEmbed {...(val as FacebookPostEmbed)} />

    case EmbedType.FacebookVideo:
      return <ComponentFacebookVideoEmbed {...(val as FacebookVideoEmbed)} />

    case EmbedType.InstagramPost:
      return <ComponentInstagramPostEmbed {...(val as InstagramPostEmbed)} />

    case EmbedType.TwitterTweet:
      return <ComponentTwitterTweetEmbed {...(val as TwitterTweetEmbed)} />

    case EmbedType.VimeoVideo:
      return <ComponentVimeoVideoEmbed {...(val as VimeoVideoEmbedSingleLang)} />

    case EmbedType.YouTubeVideo:
      return <ComponentYouTubeVideoEmbed {...(val as YouTubeVideoEmbedSingleLang)} />

    case EmbedType.SoundCloudTrack:
      return <ComponentSoundCloudTrackEmbed {...(val as SoundCloudTrackEmbed)} />

    default: {
      const v = val as OtherEmbedSingleLang
      return v.url ? <IframeEmbed {...v} /> : null
    }
  }
}

function i18nToVal(languageLaneL: string, valI18n: EmbedBlockValue) {
  let value: EmbedBlockValueSingleLang = {} as any
  if (valI18n.type.youTubeVideo) {
    value = {
      type: {
        youTubeVideo: {
          videoID: valI18n.type.youTubeVideo.videoID[languageLaneL],
        },
      },
    }
  } else if (valI18n.type.vimeoVideo) {
    value = {
      type: {
        vimeoVideo: {
          videoID: valI18n.type.vimeoVideo.videoID[languageLaneL],
        },
      },
    }
  } else {
    value = {
      type: {
        other: {
          url: valI18n.type.other?.url?.[languageLaneL],
          title: valI18n.type.other?.title?.[languageLaneL],
          width: valI18n.type.other?.width?.[languageLaneL],
          height: valI18n.type.other?.height?.[languageLaneL],
          styleCustom: valI18n.type.other?.styleCustom?.[languageLaneL],
        },
      },
    }
  }
  if (valI18n.embedCode) {
    value = {
      ...value,
      embedCode: valI18n.embedCode[languageLaneL],
    }
  }
  return value
}

function valToI18n(
  languageLaneL: string,
  valI18n: EmbedBlockValue,
  val: EmbedBlockValueSingleLang
) {
  if (val.type.youTubeVideo) {
    if (valI18n.type.youTubeVideo) {
      valI18n.type.youTubeVideo.videoID[languageLaneL] = val.type.youTubeVideo?.videoID
    } else {
      valI18n = {
        type: {
          youTubeVideo: {
            videoID: {[languageLaneL]: val.type.youTubeVideo?.videoID},
          },
        },
      }
    }
  } else if (val.type.vimeoVideo) {
    if (valI18n.type.vimeoVideo) {
      valI18n.type.vimeoVideo.videoID[languageLaneL] = val.type.vimeoVideo?.videoID
    } else {
      valI18n = {
        type: {
          vimeoVideo: {
            videoID: {[languageLaneL]: val.type.vimeoVideo?.videoID},
          },
        },
      }
    }
  } else {
    if (valI18n.type.other) {
      if (val.type.other?.url) {
        valI18n.type.other.url[languageLaneL] = val.type.other?.url
      }
      if (val.type.other?.title) {
        valI18n.type.other.title[languageLaneL] = val.type.other?.title
      }
      if (val.type.other?.styleCustom) {
        valI18n.type.other.styleCustom[languageLaneL] = val.type.other?.styleCustom
      }
      if (val.type.other?.width) {
        valI18n.type.other.width[languageLaneL] = val.type.other?.width
      }
      if (val.type.other?.height) {
        valI18n.type.other.height[languageLaneL] = val.type.other?.height
      }
    } else {
      valI18n = {
        type: {
          other: {
            url: {[languageLaneL]: val.type.other?.url || ''},
            title: {[languageLaneL]: val.type.other?.title || ''},
            styleCustom: {[languageLaneL]: val.type.other?.styleCustom || ''},
            height: {[languageLaneL]: val.type.other?.height || 0},
            width: {[languageLaneL]: val.type.other?.width || 0},
          },
        },
      }
    }
  }
  if (val.embedCode) {
    valI18n = {
      ...valI18n,
      embedCode: {
        ...valI18n.embedCode,
        [languageLaneL]: val.embedCode,
      },
    }
  }
  return valI18n
}
