import React from 'react'
import {Dropdown, IconButton, Panel, Placeholder} from 'rsuite'
import {PlaceholderInput} from './placeholderInput'
import {useTranslation} from 'react-i18next'
import {ImageRecord} from '../interfaces/interfaces'
import {DEFAULT_LANGUAGE} from '../../../config'

import Pencil from '@rsuite/icons/legacy/Pencil'
import Image from '@rsuite/icons/legacy/Image'
import Wrench from '@rsuite/icons/legacy/Wrench'
import Close from '@rsuite/icons/legacy/Close'

export interface ChooseEditImageProps {
  image?: ImageRecord
  header?: string
  disabled: boolean
  left?: number
  top?: number
  openChooseModalOpen?: () => void
  openEditModalOpen?: () => void
  removeImage?: () => void
}

export function ChooseEditImage({
  image,
  header,
  disabled,
  left,
  top,
  openChooseModalOpen,
  openEditModalOpen,
  removeImage,
}: ChooseEditImageProps): JSX.Element {
  const {t} = useTranslation()
  header = header ?? t('chooseEditImage.header')
  return (
    <Panel header={header} bodyFill={true} style={{height: 240, marginBottom: 10}}>
      {!image && disabled === true && <Placeholder.Graph />}
      <PlaceholderInput onAddClick={() => openChooseModalOpen?.()}>
        {image && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              position: 'relative',
            }}>
            <img
              style={{maxHeight: '240'}}
              src={
                image?.content.media?.[DEFAULT_LANGUAGE].media.url ??
                '/static/placeholder-240x240.png'
              }
            />
            {(openChooseModalOpen || openEditModalOpen || removeImage) && (
              <div style={{position: 'absolute', left: left, top: top}}>
                <Dropdown
                  renderToggle={(props, ref) => (
                    <IconButton
                      {...props}
                      ref={ref}
                      appearance="primary"
                      icon={<Wrench />}
                      circle
                    />
                  )}>
                  {openChooseModalOpen && (
                    <Dropdown.Item disabled={disabled} onClick={() => openChooseModalOpen()}>
                      <Image /> {t('chooseEditImage.chooseImage')}
                    </Dropdown.Item>
                  )}
                  {openEditModalOpen && (
                    <Dropdown.Item disabled={disabled} onClick={() => openEditModalOpen()}>
                      <Pencil /> {t('chooseEditImage.editImage')}
                    </Dropdown.Item>
                  )}
                  {removeImage && (
                    <Dropdown.Item disabled={disabled} onClick={() => removeImage()}>
                      <Close /> {t('chooseEditImage.removeImage')}
                    </Dropdown.Item>
                  )}
                </Dropdown>
              </div>
            )}
          </div>
        )}
      </PlaceholderInput>
    </Panel>
  )
}
