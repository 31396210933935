import React, {useState} from 'react'
import {nanoid} from 'nanoid'
import {Button, Drawer} from 'rsuite'
import {ListInput, ListValue, FieldProps} from '../atoms/listInput'
import {GalleryImageEdge} from '../blocks/types'
import {useTranslation} from 'react-i18next'
import {ChooseEditImage} from '../atoms/chooseEditImage'
import {Configs, ContentEditor, RefSelectPanel, useRecordHook} from '@dudagroup/editor'
import {ImageRecord} from '../interfaces/interfaces'
import {MODEL_MEDIA} from '../../../config'
import {getEmtpyI18nStringObject} from '../../../common/tools'

export interface GalleryListEditPanelProps {
  readonly id?: string
  readonly initialImages: GalleryImageEdge[]
  readonly configs: Configs
  onSave?(images: GalleryImageEdge[]): void
  onClose?(): void
}

export function GalleryListEditPanel({
  initialImages,
  onSave,
  onClose,
  configs,
}: GalleryListEditPanelProps) {
  const [images, setImages] = useState<ListValue<GalleryImageEdge>[]>(() =>
    initialImages.map((value) => ({
      id: nanoid(),
      value,
    }))
  )

  const {t} = useTranslation()

  return (
    <>
      <Drawer.Header>
        <Drawer.Title>{t('blocks.imageGallery.panels.editGallery')}</Drawer.Title>

        <Drawer.Actions>
          <Button appearance={'primary'} onClick={() => onSave?.(images.map(({value}) => value))}>
            {t('blocks.imageGallery.panels.save')}
          </Button>
          <Button appearance={'subtle'} onClick={() => onClose?.()}>
            {t('blocks.imageGallery.panels.close')}
          </Button>
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body>
        <ListInput
          value={images}
          onChange={(images) => setImages(images)}
          defaultValue={{image: null, caption: getEmtpyI18nStringObject()}}>
          {(props) => <GalleryListItem {...props} configs={configs} />}
        </ListInput>
      </Drawer.Body>
    </>
  )
}

export function GalleryListItem({
  value,
  onChange,
  configs,
}: FieldProps<GalleryImageEdge> & {configs: Configs}) {
  const [isChooseModalOpen, setChooseModalOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)

  const {image: imageRef, caption} = value
  const {t} = useTranslation()
  const imageRecord = useRecordHook<ImageRecord>(imageRef)

  return (
    <>
      <div>
        <ChooseEditImage
          header={''}
          image={imageRecord}
          disabled={false}
          left={5}
          top={0}
          openChooseModalOpen={() => setChooseModalOpen(true)}
          openEditModalOpen={() => setEditModalOpen(true)}
          removeImage={() => onChange?.({...value, image: null})}
        />
        {/* <Form fluid={true}>
          <Form.Group>
            <Form.ControlLabel>{t('blocks.imageGallery.panels.caption')}</Form.ControlLabel>
            <FormControl
              rows={1}
              as="textarea"
              value={caption}
              onChange={caption => onChange({...value, caption})}
            />
          </Form.Group>
        </Form> */}
      </div>

      <Drawer
        open={isChooseModalOpen}
        placement={'bottom'}
        size="full"
        onClose={() => setChooseModalOpen(false)}>
        <Drawer.Body>
          <RefSelectPanel
            refConfig={{
              [MODEL_MEDIA]: {
                scope: 'local',
              },
            }}
            configs={configs}
            onClose={() => setChooseModalOpen(false)}
            onSelectRef={(ref) => {
              setChooseModalOpen(false)
              onChange((value) => ({...value, image: ref}))
            }}
          />
        </Drawer.Body>
      </Drawer>
      {imageRef && (
        <Drawer
          open={isEditModalOpen}
          placement={'bottom'}
          size="full"
          backdrop="static"
          onClose={() => setEditModalOpen(false)}>
          <ContentEditor
            onBack={() => setEditModalOpen(false)}
            id={imageRef.recordId}
            type={MODEL_MEDIA}
            configs={configs}></ContentEditor>
        </Drawer>
      )}
    </>
  )
}
