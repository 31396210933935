import React, {useEffect, useReducer, useState} from 'react'
import {BlockProps} from '../atoms/blockList'
import {TableBlockValue} from './types'
import {Form, Button, Drawer, Grid, Input, Panel, Col, Row} from 'rsuite'
import LanguageControl from '@dudagroup/editor/lib/client/atoms/contentEdit/LanguageControl'
import {
  ContentEditActionEnum,
  contentReducer,
} from '@dudagroup/editor/lib/client/control/contentReducer'
import {useTranslation} from 'react-i18next'
import {RichTextBlock} from '@dudagroup/editor'
import {ContentModelSchemaFieldRichText} from '@dudagroup/api'
import {getEmptyRichText} from '../utility'

export type TableEditProps = BlockProps<TableBlockValue>

export function TableHeaderEdit({
  value,
  onChange,
  disabled,
  configs,
  currentPanelId,
  onClose,
  langUi,
  autofocus,
  model,
}: TableEditProps & {currentPanelId: string | null; onClose: (val: any) => void}) {
  const {t} = useTranslation()

  const currentPanel = value.header

  const [langLane1, setLangLane1] = useState(configs.apiConfig.languages.languages?.[0]?.tag)
  const [langLane2, setLangLane2] = useState(configs.apiConfig.languages.languages?.[1]?.tag)

  const contentConfig = configs.extensionMerged.find((config) => {
    return config.identifier === 'page'
  })

  const [{record}, dispatcher] = useReducer(contentReducer, {
    record: {blocks: currentPanel?.content},
    hasChanged: false,
  })

  useEffect(() => {
    dispatcher({
      type: ContentEditActionEnum.setInitialState,
      value: {blocks: currentPanel?.content},
    })
  }, [currentPanel])

  if (!(currentPanelId && record?.blocks && contentConfig)) {
    return null
  }

  const header = (
    <LanguageControl
      languagesConfig={configs.apiConfig.languages}
      langLaneL={langLane1}
      langLaneR={langLane2}
      setLangLaneL={setLangLane1}
      setLangLaneR={setLangLane2}
    />
  )

  return (
    <Drawer
      open={!!currentPanelId}
      placement={'bottom'}
      size="full"
      backdrop="static"
      onClose={() => {
        onClose(record.blocks)
      }}>
      <Drawer.Header>
        <Drawer.Title>{t('blocks.table.tableHeader')}</Drawer.Title>

        <Drawer.Actions>
          <Button
            appearance={'primary'}
            color={'green'}
            onClick={() => {
              onClose(record.blocks)
            }}>
            {t('blockList.apply')}
          </Button>
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body>
        <Grid>
          <div className="wep-editor-langcontrol">{header}</div>

          {currentPanel.content.map((column: any, index: any) => {
            // if (columnCount <= index) return null

            const text = column.content.richText.text

            const config = (
              (model.fields.header as any).fields.content.contentType.cases.richText.fields
                .text as ContentModelSchemaFieldRichText
            ).config

            return (
              <div key={column.id}>
                <Panel
                  bordered
                  style={{
                    overflow: 'visible',
                    marginTop: 10,
                    marginBottom: 10,
                  }}>
                  <div className="wep-label" style={{marginBottom: 24}}>
                    <Form.ControlLabel style={{display: 'inline-block'}}>
                      <strong>{`${t('blocks.table.column')} ${index + 1}`}</strong>
                    </Form.ControlLabel>
                  </div>

                  <Row
                    className="show-grid"
                    style={{display: 'flex', alignItems: 'top', marginBottom: 24}}>
                    <Col xs={14}>
                      <RichTextBlock
                        languageContext={{
                          langLane1: langLane1,
                          langLane2: langLane2,
                          langUi: langUi,
                          languagesConfig: configs.apiConfig.languages,
                        }}
                        config={config}
                        value={text[langLane1] || getEmptyRichText('')}
                        onChange={(val) => {
                          onChange(val, [
                            'header',
                            'content',
                            index,
                            'content',
                            'richText',
                            'text',
                            langLane1,
                          ])
                        }}
                        autofocus={autofocus}
                        disabled={disabled}
                      />
                    </Col>
                    <Col xs={1} style={{textAlign: 'center', paddingTop: '5px'}}></Col>
                    <Col xs={9}>
                      <RichTextBlock
                        languageContext={{
                          langLane1: langLane1,
                          langLane2: langLane2,
                          langUi: langUi,
                          languagesConfig: configs.apiConfig.languages,
                        }}
                        config={config}
                        value={text[langLane2] || getEmptyRichText('')}
                        onChange={(val) => {
                          // onChange(val, [
                          //   'header',
                          //   'content',
                          //   index,
                          //   'content',
                          //   'richText',
                          //   'text',
                          //   langLane2
                          // ])
                        }}
                        autofocus={false}
                        displayOnly={true}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </Panel>
              </div>
            )
          })}
        </Grid>
      </Drawer.Body>
    </Drawer>
  )
}
