import React, {useRef, useEffect} from 'react'
import {TypographicTextArea} from '../atoms/typographicTextArea'
import {BlockProps} from '../atoms/blockList'
import {useTranslation} from 'react-i18next'
import {TitleBlockValue} from './types'
import {I18nWrapper} from '../atoms/i18nWrapper'

export type TitleBlockProps = BlockProps<TitleBlockValue>

export function TitleBlock({
  value,
  onChange,
  autofocus,
  disabled,
  languageLaneL,
  languageLaneR,
}: TitleBlockProps) {
  const {title, lead} = value
  const focusRef = useRef<HTMLTextAreaElement>(null)
  const {t} = useTranslation()

  useEffect(() => {
    if (autofocus) focusRef.current?.focus()
  }, [])

  return (
    <I18nWrapper
      lane1={
        <>
          <TypographicTextArea
            ref={focusRef}
            variant="title"
            align="center"
            placeholder={t('blocks.title.title')}
            value={title[languageLaneL]}
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.value, ['title', languageLaneL])
            }}
          />
          <TypographicTextArea
            variant="body1"
            align="center"
            placeholder={t('blocks.title.leadText')}
            value={lead[languageLaneL]}
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.value, ['lead', languageLaneL])
            }}
          />
        </>
      }
      lane2={
        <>
          <TypographicTextArea
            variant="title"
            align="center"
            placeholder={t('blocks.title.title')}
            value={title[languageLaneR]}
            disabled={true}
          />
          <TypographicTextArea
            variant="body1"
            align="center"
            placeholder={t('blocks.title.leadText')}
            value={lead[languageLaneR]}
            disabled={true}
          />
        </>
      }></I18nWrapper>
  )
}
