import React from 'react'
import {BlockProps} from '../atoms/blockList'
import {RichTextBlockExampleValue} from './types'
import {RichTextBlock} from '@dudagroup/editor'
import {Col, Row, SelectPicker, Form} from 'rsuite'
import {getEmptyRichText} from '../utility'
import {ContentModelSchemaFieldRichText} from '@dudagroup/api'
import {useTranslation} from 'react-i18next'

export type RichTextBlockProps = BlockProps<RichTextBlockExampleValue>
export function RichTextBlockExampleBlock({
  value,
  onChange,
  model,
  autofocus,
  disabled,
  languageLaneL,
  languageLaneR,
  langUi,
  configs,
}: RichTextBlockProps) {
  const {text, backgroundColor} = value
  const {t} = useTranslation()
  const data = (model.fields.backgroundColor as any).values.map((item: any) => {
    return {
      label: item.description,
      value: item.value,
    }
  })
  return (
    <>
      <Row className="show-grid" style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
        <Col xs={14}>
          <Form.ControlLabel style={{marginRight: 10}}>
            {t('blockList.backgroundColor')}
          </Form.ControlLabel>
          <SelectPicker
            style={{width: '100%'}}
            data={data}
            value={backgroundColor}
            searchable={false}
            onChange={(val) => {
              onChange(val, ['backgroundColor'])
            }}
          />
        </Col>
      </Row>
      <Row className="show-grid" style={{display: 'flex', alignItems: 'top', marginBottom: 24}}>
        <Col xs={14}>
          <RichTextBlock
            languageContext={{
              langLane1: languageLaneL,
              langLane2: languageLaneR,
              langUi: langUi,
              languagesConfig: configs.apiConfig.languages,
            }}
            config={(model.fields.text as ContentModelSchemaFieldRichText).config}
            value={text[languageLaneL] || getEmptyRichText('')}
            onChange={(val) => {
              onChange(val, ['text', languageLaneL])
            }}
            autofocus={autofocus}
            disabled={disabled}
          />
        </Col>
        <Col xs={1} style={{textAlign: 'center', paddingTop: '5px'}}></Col>
        <Col xs={9}>
          <RichTextBlock
            languageContext={{
              langLane1: languageLaneL,
              langLane2: languageLaneR,
              langUi: langUi,
              languagesConfig: configs.apiConfig.languages,
            }}
            config={(model.fields.text as ContentModelSchemaFieldRichText).config}
            value={text[languageLaneR] || getEmptyRichText('')}
            onChange={(val) => {
              onChange(val, ['text', languageLaneR])
            }}
            autofocus={autofocus}
            displayOnly={true}
            disabled={true}
          />
        </Col>
      </Row>
    </>
  )
}
