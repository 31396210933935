import React, {useState} from 'react'
import {BlockProps} from '../atoms/blockList'
import {AccordionBlockValue, AccordionBlockValueItem} from './types'
import {
  IconButton,
  Panel,
  PanelGroup,
  ButtonToolbar,
  Col,
  Form,
  Row,
  SelectPicker,
  Placeholder,
} from 'rsuite'
import {AccordionEdit} from './accordionEdit'
import {getEmptyI18nValue} from '../utility'
import {nanoid} from 'nanoid'
import {ContentEditActionEnum} from '@dudagroup/editor/lib/client/control/contentReducer'
import {useTranslation} from 'react-i18next'

import PlusCircle from '@rsuite/icons/legacy/PlusCircle'
import Pencil from '@rsuite/icons/legacy/Pencil'
import CaretUp from '@rsuite/icons/legacy/CaretUp'
import CaretDown from '@rsuite/icons/legacy/CaretDown'
import Bomb from '@rsuite/icons/legacy/Bomb'

export type AccordionBlockProps = BlockProps<AccordionBlockValue>

export function AccordionBlock(props: AccordionBlockProps) {
  const {value, onChange, configs, languageLaneL, dispatch, path, model} = props
  const {accordion, backgroundColor} = value
  const [currentPanelId, setCurrentPanelId] = useState<string | null>(null)
  const [currentPanelIndex, setCurrentPanelIndex] = useState<number>(-1)
  const {t} = useTranslation()

  const data = (model.fields.backgroundColor as any).values.map((item: any) => {
    return {
      label: item.description,
      value: item.value,
    }
  })

  const accordionPanels = accordion.map((_item, i, array) => {
    const {content: item} = _item

    return (
      <Panel key={item.id} header={item.title[languageLaneL] || '...'}>
        <>
          <ButtonToolbar>
            <IconButton
              icon={<Pencil />}
              appearance={'ghost'}
              placement="left"
              onClick={() => {
                setCurrentPanelIndex(i)
                setCurrentPanelId(item.id)
              }}>
              {t('blockList.edit')}
            </IconButton>
            <IconButton
              icon={<CaretUp />}
              appearance={'ghost'}
              placement="left"
              onClick={() => {
                dispatch({
                  type: ContentEditActionEnum.splice,
                  path: [...path, 'accordion'],
                  start: i - 1,
                  delete: 2,
                  insert: [_item, array[i - 1]],
                })
              }}
              disabled={i === 0}>
              {t('blockList.moveUp')}
            </IconButton>
            <IconButton
              icon={<CaretDown />}
              appearance={'ghost'}
              placement="left"
              onClick={() => {
                dispatch({
                  type: ContentEditActionEnum.splice,
                  path: [...path, 'accordion'],
                  start: i,
                  delete: 2,
                  insert: [array[i + 1], _item],
                })
              }}
              disabled={i === array.length - 1}>
              {t('blockList.moveDown')}
            </IconButton>
            <IconButton
              icon={<Bomb />}
              appearance={'ghost'}
              placement="left"
              onClick={() => {
                dispatch({
                  type: ContentEditActionEnum.splice,
                  path: [...path, 'accordion'],
                  start: i,
                  delete: 1,
                })
              }}>
              {t('blockList.delete')}
            </IconButton>
          </ButtonToolbar>

          <Placeholder.Paragraph />
        </>
      </Panel>
    )
  })

  return (
    <>
      <Row className="show-grid" style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
        <Col xs={14}>
          <Form.ControlLabel style={{marginRight: 10}}>
            {t('blockList.backgroundColor')}
          </Form.ControlLabel>
          <SelectPicker
            style={{width: '100%'}}
            data={data}
            value={backgroundColor}
            searchable={false}
            onChange={(val) => {
              onChange(val, ['backgroundColor'])
            }}
          />
        </Col>
      </Row>
      <IconButton
        placement="left"
        appearance="ghost"
        icon={<PlusCircle />}
        onClick={() => {
          const accordionItem = {
            content: {
              id: nanoid(),
              title: getEmptyI18nValue(configs.apiConfig.languages, t('blockList.newPanel')),
              content: [],
            } as AccordionBlockValueItem,
          }
          onChange([...accordion, accordionItem], ['accordion'])
        }}>
        {t('blockList.addAccordionGroup')}
      </IconButton>
      <br />
      <br />
      {accordionPanels.length > 0 ? (
        <PanelGroup accordion bordered>
          {accordionPanels}
        </PanelGroup>
      ) : null}

      <AccordionEdit
        {...props}
        currentPanelId={currentPanelId}
        onClose={(val) => {
          onChange(val, ['accordion', currentPanelIndex, 'content', 'content'])
          setCurrentPanelId(null)
        }}></AccordionEdit>
    </>
  )
}
