import React, {useState} from 'react'
import {BlockProps} from '../atoms/blockList'
import {ListInput, FieldProps} from '../atoms/listInput2'
import {
  Configs,
  Reference,
  ReferenceButton,
  RefSelectDrawer,
  isFunctionalUpdate,
} from '@dudagroup/editor'
import {FaqListBlockValue} from './types'
import {Drawer} from 'rsuite'

export function FaqListBlock({value, onChange, configs, disabled}: BlockProps<FaqListBlockValue>) {
  return (
    <ListInput
      value={value.faqList}
      onChange={(items) => {
        const val = isFunctionalUpdate(items) ? items(value.faqList) : items
        onChange(val, ['faqList'])
      }}
      defaultValue={{} as any}
      disabled={disabled}>
      {(props) => <FaqListElement {...props} configs={configs} />}
    </ListInput>
  )
}

function FaqListElement({value, configs, onChange}: FieldProps<Reference> & {configs: Configs}) {
  const [isChooseModalOpen, setChooseModalOpen] = useState(false)
  return (
    <>
      <div style={{flexGrow: 1}}>
        <ReferenceButton
          configs={configs}
          reference={value.recordId ? value : null}
          onClick={() => setChooseModalOpen(true)}
          onClose={() => {
            onChange((value) => ({...value, recordId: '', contentType: ''}))
          }}></ReferenceButton>
        <Drawer
          placement={'bottom'}
          open={isChooseModalOpen}
          size="full"
          onClose={() => setChooseModalOpen(false)}>
          <RefSelectDrawer
            configs={configs}
            refConfig={{
              ['faq']: {
                scope: 'local',
              },
            }}
            onClose={() => setChooseModalOpen(false)}
            onSelectRef={(ref) => {
              setChooseModalOpen(false)
              onChange((value) => {
                return {...value, ...ref}
              })
            }}
          />
        </Drawer>
      </div>
    </>
  )
}
