import React, {useState, useEffect} from 'react'

import {Drawer, Dropdown, IconButton, Panel} from 'rsuite'

import {PlaceholderInput} from '../atoms/placeholderInput'
import {TypographicTextArea} from '../atoms/typographicTextArea'
import {BlockProps} from '../atoms/blockList'

import {ImageGalleryBlockValue} from './types'
import {GalleryListEditPanel} from '../panel/galleryListEditPanel'
import {I18nWrapper} from '../atoms/i18nWrapper'
import {useTranslation} from 'react-i18next'
import {ContentEditor, RefSelectPanel, useRecordHook} from '@dudagroup/editor'
import {ImageRecord} from '../interfaces/interfaces'
import {DEFAULT_LANGUAGE, MODEL_MEDIA} from '../../../config'
import {getEmtpyI18nStringObject} from '../../../common/tools'

import PlusCircle from '@rsuite/icons/legacy/PlusCircle'
import Pencil from '@rsuite/icons/legacy/Pencil'
import Image from '@rsuite/icons/legacy/Image'
import Wrench from '@rsuite/icons/legacy/Wrench'
import List from '@rsuite/icons/legacy/List'
import ArrowCircleLeft from '@rsuite/icons/legacy/ArrowCircleLeft'
import ArrowCircleRight from '@rsuite/icons/legacy/ArrowCircleRight'

export function ImageGalleryBlock({
  value,
  onChange,
  autofocus,
  disabled,
  configs,
  languageLaneL,
  languageLaneR,
}: BlockProps<ImageGalleryBlockValue>) {
  const [isGalleryListEditModalOpen, setGalleryListEditModalOpen] = useState(false)

  const [isChooseModalOpen, setChooseModalOpen] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)

  const [index, setIndex] = useState(0)

  const item = value.images[index]

  const imageRef = item?.image
  const record = useRecordHook<ImageRecord>(imageRef)

  const caption = item?.caption ?? getEmtpyI18nStringObject()

  const hasPrevious = index > 0
  const hasNext = index < value.images.length - 1

  const isNewIndex = !imageRef && !caption && index >= value.images.length

  const {t} = useTranslation()

  useEffect(() => {
    if (autofocus) {
      setGalleryListEditModalOpen(true)
    }
  }, [autofocus])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 10,
        }}>
        <div
          style={{
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 1,
          }}>
          <IconButton
            icon={<List />}
            onClick={() => setGalleryListEditModalOpen(true)}
            disabled={disabled}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexBasis: 0,
            justifyContent: 'center',
            flexGrow: 1,
            flexShrink: 1,
          }}>
          <p style={{color: 'gray'}} color="gray">
            {index + 1} / {Math.max(index + 1, value.images.length)} {isNewIndex ? '(New)' : ''}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexBasis: 0,
            justifyContent: 'flex-end',
            flexGrow: 1,
            flexShrink: 1,
          }}>
          <IconButton
            icon={<ArrowCircleLeft />}
            onClick={() => setIndex((index) => index - 1)}
            disabled={disabled || !hasPrevious}
            style={{
              marginRight: 5,
            }}
          />
          <IconButton
            icon={<ArrowCircleRight />}
            onClick={() => setIndex((index) => index + 1)}
            disabled={disabled || !hasNext}
            style={{
              marginRight: 10,
            }}
          />
          <IconButton
            icon={<PlusCircle />}
            onClick={() => setIndex(value.images.length)}
            disabled={disabled || isNewIndex}
          />
        </div>
      </div>
      <Panel
        bordered={true}
        bodyFill={true}
        style={{
          height: 300,
          overflow: 'hidden',
          marginBottom: 10,
        }}>
        <PlaceholderInput onAddClick={() => setChooseModalOpen(true)}>
          {record?.content.media?.[DEFAULT_LANGUAGE].media?.image && (
            <div
              style={{
                padding: 0,
                position: 'relative',
                height: '100%',
                backgroundSize: `${
                  record.content.media[DEFAULT_LANGUAGE].media.image.height > 300
                    ? 'contain'
                    : 'auto'
                }`,
                backgroundPositionX: 'center',
                backgroundPositionY: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${
                  record.content.media[DEFAULT_LANGUAGE].media.url ??
                  'https://via.placeholder.com/240x240'
                })`,
              }}>
              <Dropdown
                renderToggle={(props, ref) => (
                  <IconButton {...props} ref={ref} appearance="subtle" icon={<Wrench />} circle />
                )}>
                <Dropdown.Item onClick={() => setChooseModalOpen(true)}>
                  <Image /> {t('blocks.image.overview.chooseImage')}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setEditModalOpen(true)}>
                  <Pencil /> {t('blocks.image.overview.editImage')}
                </Dropdown.Item>
                {/* TODO: Meta sync */}
              </Dropdown>
            </div>
          )}
        </PlaceholderInput>
      </Panel>
      <I18nWrapper
        lane1={
          <TypographicTextArea
            variant="subtitle2"
            align="center"
            placeholder={t('blocks.imageGallery.overview.caption')}
            value={caption[languageLaneL]}
            disabled={disabled}
            onChange={(e) => {
              onChange(
                Object.assign([], value.images, {
                  [index]: {
                    image: imageRef,
                    caption: {...value.images[index].caption, [languageLaneL]: e.target.value},
                  },
                }),
                ['images']
              )
            }}
          />
        }
        lane2={
          <TypographicTextArea
            variant="subtitle2"
            align="center"
            placeholder={t('blocks.imageGallery.overview.caption')}
            value={caption[languageLaneR]}
            disabled={disabled}
            onChange={(e) => {
              onChange(
                Object.assign([], value.images, {
                  [index]: {
                    image: imageRef,
                    caption: {...value.images[index].caption, [languageLaneR]: e.target.value},
                  },
                }),
                ['images']
              )
            }}
          />
        }></I18nWrapper>

      <Drawer
        open={isChooseModalOpen}
        placement={'bottom'}
        size="full"
        onClose={() => setChooseModalOpen(false)}>
        <Drawer.Body>
          <RefSelectPanel
            refConfig={{
              [MODEL_MEDIA]: {
                scope: 'local',
              },
            }}
            configs={configs}
            onClose={() => setChooseModalOpen(false)}
            onSelectRef={(ref) => {
              setChooseModalOpen(false)
              onChange(
                Object.assign([], value.images, {
                  [index]: {
                    image: ref,
                    caption,
                  },
                }),
                ['images']
              )
            }}
          />
        </Drawer.Body>
      </Drawer>
      {imageRef && (
        <Drawer
          open={isEditModalOpen}
          placement={'bottom'}
          size="full"
          backdrop="static"
          onClose={() => setEditModalOpen(false)}>
          <ContentEditor
            onBack={() => setEditModalOpen(false)}
            id={imageRef.recordId}
            type={MODEL_MEDIA}
            configs={configs}></ContentEditor>
        </Drawer>
      )}
      <Drawer
        open={isGalleryListEditModalOpen}
        size={'sm'}
        onClose={() => setGalleryListEditModalOpen(false)}>
        <GalleryListEditPanel
          configs={configs}
          initialImages={value.images}
          onSave={(images) => {
            onChange(Object.assign([], images), ['images'])
            setGalleryListEditModalOpen(false)
          }}
          onClose={() => setGalleryListEditModalOpen(false)}
        />
      </Drawer>
    </>
  )
}
